<template>
  <div>
    <header>
      <v-row>
        <v-col cols="12" md="2" sm="4">
          <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="datesFormatted"
                label="Fecha desde / hasta"
                readonly
                v-on="on"
                prepend-icon="mdi-calendar"
              ></v-text-field>
            </template>
            <v-date-picker v-model="dates" locale="es-ar" range></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="3" sm="4" class="filtro-sede">
          <v-select
            :items="sedes"
            item-text="nombre"
            item-value="idObraSocial"
            prepend-icon="mdi-calendar-multiple"
            v-model="sede"
            label="Sede"
            dense
            @change="getMotivosConsultas"
          ></v-select>
        </v-col>
        <v-col class="reload-btn">
          <v-btn color="primary" @click="getMotivosConsultas">Recargar</v-btn>
        </v-col>
      </v-row>
    </header>
    <main>
      <v-row>
        <v-col cols="12" md="3" sm="4">
          <v-data-table
            :headers="headers"
            :items="listaMotivosConsulta"
            class="elevation-1"
            locale="es-ar"
            item-key="idMotivoConsulta"
            :items-per-page="10"
            ref="myTable"
            :disable-sort="true"
            :hide-default-footer="true"
            no-data-text="Sin datos en las fechas seleccionadas"
          >
          </v-data-table>
        </v-col>
        <v-col cols="12" md="9" sm="4">
          <bar-chart
            v-if="motivosData.datasets[0].data.length"
            :style="myStyle"
            :chartData="motivosData"
            :options="options"
          ></bar-chart>
        </v-col>
      </v-row>
    </main>
  </div>
</template>
<script>
import BarChart from "./BarChart.js";
import reportesMixin from "../../../mixins/reportesMixin";
import { getData } from "../../../api/apiService";
import moment from "moment";

export default {
  name: "ReportesMotivosConsulta",
  mixins: [reportesMixin],
  components: { BarChart },
  data() {
    return {
      menu2: false,
      dates: [],
      sedes: [],
      sede: 0,
      datesFormatted: "",
      selected: null,
      headers: [
        { text: "Motivo Consulta", value: "motivoConsultaString" },
        { text: "Cantidad", value: "cantidad" },
        { text: "Porcentaje", value: "porcentaje" },
      ],
      listaMotivosConsulta: [],
      motivosData: {
        labels: [],
        datasets: [
          {
            label: "Gráfico de Motivos de Consulta",
            data: [],
            borderWidth: 1,
            tension: 0.1,
            backgroundColor: [
              "#FF5733", // Naranja rojizo
              "#33FF57", // Verde lima
              "#3357FF", // Azul brillante
              "#FF33A8", // Rosa fuerte
              "#FFBD33", // Amarillo dorado
              "#33FFBD", // Verde menta
              "#8A33FF", // Violeta intenso
              "#33FFF5", // Cian brillante
              "#FFD433", // Amarillo cálido
              "#FF3380", // Rosa oscuro
              "#A833FF", // Púrpura vibrante
              "#33A8FF", // Azul celeste
              "#57FF33", // Verde claro
              "#FF5733", // Naranja fuerte
              "#FF33F5", // Magenta
              "#33FF8A", // Verde suave
            ],
            borderColor: "transparent",
            borderWidth: 0,
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
        responsive: true,
        maintainAspectRatio: false,
      },
      motivosLineData: {
        labels: [
          "01/2023",
          "02/2023",
          "03/2023",
          "04/2023",
          "05/2023",
          "06/2023",
          "07/2023",
          "08/2023",
        ],
        datasets: [],
      },
      optionsLine: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  watch: {
    dates(val) {
      if (val.length === 2) {
        moment.locale("fr");
        this.datesFormatted =
          moment(val[0]).format("L") + " - " + moment(val[1]).format("L");
        this.menu2 = false;
        this.getMotivosConsultas();
      }
    },
  },
  computed: {
    myStyle() {
      return {
        height: "500px",
      };
    },
    datasets() {
      return {
        1: {
          label: "Sobrepeso",
          data: [10, 3, 8, 11, 9, 10, 15, 11],
          borderWidth: 1,
          tension: 0.1,
          backgroundColor: "transparent",
          borderColor: "#FF5733",
          borderWidth: 1,
        },
        2: {
          label: "Cambio de Hábitos Alimenticios",
          data: [7, 5, 8, 11, 11, 13, 15, 9],
          borderWidth: 1,
          tension: 0.1,
          backgroundColor: "transparent",
          borderColor: "#33FF65",
          borderWidth: 1,
        },
        3: {
          label: "Patología Coexistente",
          data: [11, 15, 7, 6, 10, 10, 14, 10],
          borderWidth: 1,
          tension: 0.1,
          backgroundColor: "transparent",
          borderColor: "#3380FF",
          borderWidth: 1,
        },
        4: {
          label: "Bajo Peso",
          data: [8, 2, 9, 9, 7, 4, 11, 5],
          borderWidth: 1,
          tension: 0.1,
          backgroundColor: "transparent",
          borderColor: "#F433FF",
          borderWidth: 1,
        },
        5: {
          label: "TCA",
          data: [14, 10, 11, 12, 10, 13, 8, 5],
          borderWidth: 1,
          tension: 0.1,
          backgroundColor: "transparent",
          borderColor: "#FFA533",
          borderWidth: 1,
        },
      };
    },
  },
  methods: {
    setInitialData() {
      const primerDiaMes = moment().startOf("month").format("YYYY-MM-DD");
      const diaActual = moment().format("YYYY-MM-DD");
      this.dates = [primerDiaMes, diaActual];
    },
    async getMotivosConsultas() {
      try {
        const params = {
          idSede: this.sede,
          fechaDesde: this.dates[0],
          fechaHasta: this.dates[1],
          idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
        };
        const pacientesData = await getData(
          "/Dashboard/GetMotivosConsultas",
          params
        );
        const total = pacientesData.reduce((acc, item) => {
          return acc + item.cantidad;
        }, 0);

        this.motivosData.datasets[0].data = [];
        await this.$nextTick();
        this.motivosData.labels = [];

        pacientesData.forEach((item) => {
          item.porcentaje = `${(
            parseFloat(item.cantidad / total) * 100
          ).toFixed(2)}%`;
          this.motivosData.labels.push(
            `${item.porcentaje} ${item.motivoConsultaString} (${item.cantidad})`
          );
          this.motivosData.datasets[0].data.push(item.cantidad);
        });
        this.listaMotivosConsulta = pacientesData;
      } catch (error) {
        console.log(error.message);
      }
    },
  },
  async created() {
    this.$loading(true);
    this.setInitialData();
    await this.getSedes();
    await this.getMotivosConsultas();
    this.$loading(false);
  },
};
</script>
<style>
.filtro-sede {
  margin-top: 17px;
}
</style>
